import React from 'react';
import './PDFDetailHistory.css';
import PDFDetailHistoryItem from './PDFDetailHistoryItem.js';

class PDFDetailHistory extends React.Component {

    render() {
        var histItems = "";
        if (typeof(this.props.history) !== "undefined"){
            histItems = this.props.history.map((hist) =>
                <PDFDetailHistoryItem key={hist._id.$oid} item={hist} />
            );
        };
        return (
            <div>
                <div className="hist_header highlighted">
                History
                </div>
            {histItems}
            </div>
        )
    }

}

export default PDFDetailHistory
