import React from 'react';
//import ReactDOM from 'react-dom';
import axios from 'axios';
import PublicHeader from './PublicHeader.js';
import './PDFSearch.css';
//const axios = require('axios')

//const e = React.createElement;

class PDFSearch extends React.Component {
        //<input type="text" id="searchText" class="center" placeholder="Enter a batch #..." /><!-- SteepHill-BK80920-Standard Potency.pdf -->
        //<div id="searchButton"> </div>
        //<div id="embeddedPDF"> </div>

    constructor(props){
        super(props);
        this.state = {
            value: '',
            searchAttempted: false};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount(){
        if (this.props.match.params.batchNum){
            await this.setState({value: this.props.match.params.batchNum});
            await this.doHandleSubmit();
        };
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    handleSubmit(event) {
        console.log('A Batch Number was entered:: ' + this.state.value);
        event.preventDefault();
        this.doHandleSubmit();
    }

    async doHandleSubmit(){
        await this.getMetadata();
        this.setState({ searchAttempted: true });
    }

    async getMetadata(){
        var bn = this.state.value;
        var response = await axios.get('https://documents.americanextractions.com/api/coa_meta.php?batchNum=' + bn, {
            // this doesn't seem to work with a GET request; saving syntax for use in a POST or PUT
            data: { batchNum: bn }
        });
        var meta = response.data[0];
        if (meta !== null && typeof(meta) !== 'undefined' && meta.fileName !==null){
            this.setState({
                validSearchResults: true,
                meta: meta
            });
        } else {
            this.setState({
                validSearchResults: false,
                meta: {}
            });
        }
    }

    render() {
        var searchResults = "";
        if (this.state.searchAttempted){
            searchResults = this.state.validSearchResults
            ? <PDFSearchResult meta={this.state.meta} />
            : <PDFSearchError/>
        }
        return (
            <div>
            <PublicHeader />
            <div className="center">
                Enter the batch number or lot code for your product.<br />
                It can be found on the label or the bottom of the container.<br />
            </div>
            <form onSubmit={this.handleSubmit} className="center">
                <div>                
                    <input type="text" id="searchText" placeholder="Enter a batch #..." value={this.state.value} onChange={this.handleChange} />
                </div>
                <div>
                    <input type="submit" value="Search" />
                </div>
            </form>
            <div className="center">
                {searchResults}
            </div>
            </div>
        )
    }
}

class PDFSearchError extends React.Component {
    render() {
        return (
            <div>
                No documents found for that batch number
            </div>
        )
    }
}

class PDFDownloadLink extends React.Component {

    render(){
        return(
            <div>
                If your browser doesn't render the PDF properly, click <a href={"https://documents.americanextractions.com/api/coa_doc.php?fileName=" + this.props.fileName}>here</a> to download.
            </div>
        )
    }
}

class PDFSearchResult extends React.Component {
    constructor(props){
        super(props);
        console.log(props);
    }    

    render() {
        var customerString = "";
        if (this.props.meta.customer){
            customerString = this.props.meta.customer;
            if (this.props.meta.customerWebsite){
                customerString = <p>Manufactured for <a href={this.props.meta.customerWebsite} target="_blank" rel="noopener noreferrer">{customerString}</a></p>
            } else {
                customerString = <p>Manufactured for {this.props.meta.customer}</p>
            }
        }
        var descriptionString = this.props.meta.description || "";
        return (
            <div>
                {/*<table>
                  <tbody>
                    <tr>
                        <td>{this.props.meta.batchNum}</td>
                        <td>{this.props.meta.updatedBy}</td>
                    </tr>
                  </tbody>
                </table> */}
                {customerString}
                <p>{descriptionString}</p>
                <object data={"https://documents.americanextractions.com/api/coa_doc.php?fileName=" + this.props.meta.fileName}>
                {this.props.meta.fileName ? "Your browser does not support embedding PDF objects." : "Documents coming soon!"}
                </object>
                <br />
                {this.props.meta.fileName ? <PDFDownloadLink fileName={this.props.meta.fileName} /> : ""}
                <br />
                Disclaimer:  Due to the sensitive nature of advanced laboratory equipment used to produce these test results (such as HPLC, or High-Performance Liquid Chromatography), our third-party labs have advised us that test results may vary by as much as +/- 5%
                <br />
                Due to our unique <a href="https://www.americanextractions.com/extraction-process">Cellular Deconstruction Technology</a>, our products tend to form microscopic crystals at lower temperatures and concentrations than other CBD products.  This can cause unpredictable results when using light-based analysis equipment such as HPLC, and may prove problematic for other analysis techniques as well.  Therefore, any attempts to reproduce these results should be undertaken with great care and attention to detail - ensure the material is properly heated and mixed prior to extracting the sample, regardless of testing methods and solvents used.
            </div>
        )
    }    
}

export default PDFSearch;


