import React from 'react';

class PDFDetailHistoryItem extends React.Component {

    constructor(props){
        super(props);
        this.state = {
                showDetail: false
            }
        this.toggleHistoryDetailView = this.toggleHistoryDetailView.bind(this);
    }

    toggleHistoryDetailView(e){
        e.preventDefault();
        var showDetail = this.state.showDetail ? false : true ;
        this.setState({ showDetail: showDetail });
    }

    render() {
        return (
            <div key={this.props.item._id.$oid} className="hist_item">
                <div onClick={this.toggleHistoryDetailView} id="hist_item_head" className="clickable">
                    {this.props.item.username} edited this object on {this.props.item.date} UTC
                </div>
                <div id="hist_item_detail" className={this.state.showDetail ? "" : "hidden"}>
                    {JSON.stringify(this.props.item.document)}
                </div>
            </div>
        )
    }
}

export default PDFDetailHistoryItem
