import React from 'react';
//import ReactDOM from 'react-dom';
import axios from 'axios';
import './PDFManager.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import PDFList from './PDFList.js';
import PDFDetail from './PDFDetail.js';

class PDFManager extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            pageNum: 1,
            docs: [],
            view: 'list'
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getDocumentDetail = this.getDocumentDetail.bind(this);
        this.closeItem = this.closeItem.bind(this);
    }

    async componentDidMount(){
        await this.getDocumentList();
    }

    handleChange(event) {
        //validate here?
        //set search term in state
    }

    handleSubmit(event) {
        this.getDocumentList();
    }

    async getDocumentList(){
        await this.props.checkToken();
        var response = await axios.get('https://documents.americanextractions.com/api/coa_meta.php?token=' + this.props.user.token, {
            // this doesn't seem to work with a GET request; saving syntax for use in a POST or PUT
            data: { }
        });
        var docs = response.data;
        if (docs !== null && typeof(docs) !== 'undefined' && docs.fileName !==null && docs.fileName !== ''){
            this.setState({docs: docs});
        }
    }

    async closeItem(){
        await this.getDocumentList();
        this.setState({view: 'list'});
    }

    getDocumentDetail(isNew, id) {
        var filteredItem = this.state.docs.filter(o => {
                return o._id.$oid === id;
            })[0] //there should only ever be one result because we're using the mongo record ID
        this.setState({
            CreateNewDocument: isNew,
            detailItem: filteredItem,
            view: 'detail'
        });
    }

    render() {
        var props;
        var component = '';
        if (this.state.view === 'detail'){
            props = {
                cancel: this.closeItem,
                new: this.state.CreateNewDocument,
                doc: this.state.detailItem,
                checkToken: this.props.checkToken,
                user: this.props.user,
                docs: this.state.docs,
            }
            console.log('calling PDFDetail with props:');
            console.log(props);
            component = <PDFDetail {...props} />
        } else if (this.state.view === 'list') {
            props = {
                docs: this.state.docs,
                getDocumentDetail: this.getDocumentDetail
            }
            component = (<PDFList {...props} />);
        }
        //this.setState({component: component});
        return (
            <div className="light-background">
            <div className="center">
            {/* preserving this to use for search in the table
            <form onSubmit={this.handleSubmit} className="center">
                <div>                
                    <input type="text" id="searchText" placeholder="Enter a batch #..." value={this.state.value} onChange={this.handleChange} />
                </div>
                <div>
                    <input type="submit" value="Search" />
                </div>
            </form>
            */}
            {component}
            </div>
            </div>
        )
    }

}

export default PDFManager;


