import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import App from './App';
import * as serviceWorker from './serviceWorker';
import PDFSearch from './PDFSearch.js';
import PrivateLayout from './PrivateLayout.js';
import { Router, Route, Switch } from "react-router";
import { createBrowserHistory } from "history";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL
});

ReactDOM.render((
    <div>
    <Router history={history} basename={process.env.PUBLIC_URL}>
        <Switch>
                <Route path="/search/:batchNum" component={PDFSearch} /> {/* this is redundant, but I'm reserving for later use */}
                <Route path="/private" component={PrivateLayout} />
                <Route component={PDFSearch} />
        </Switch>
    </Router>
    <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
    </div>
), document.getElementById('root'));



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
