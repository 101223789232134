import React from 'react';
import './index.css';
import PrivateHeader from './PrivateHeader.js';
import PublicHeader from './PublicHeader.js';
import PDFManager from './PDFManager.js';
import Keycloak from 'keycloak-js';

class PrivateLayout extends React.Component{

  constructor(props){
    super(props);
    this.state = {
            user: {},
            keycloak: {},
            lastToken: '',
         };

    this.checkToken = this.checkToken.bind(this);
  }

  componentDidMount() {
    this.authorizeUser();
    //this.setState({keycloak: this.authorizeUser()});
  }

  composeUser (){
    var user = {
        token: this.state.keycloak.token,
        refreshToken: this.state.keycloak.refreshToken,
        logoutUrl: this.state.keycloak.createLogoutUrl(),
        preferencesUrl: this.state.keycloak.createAccountUrl(),
        fullName: this.state.keycloak.tokenParsed.name,
        roles: this.state.keycloak.realmAccess.roles,
    }
    this.setState({user: user});
    console.log('composed user:');
    console.log(this.state.user);
    if (!this.state.lastToken || this.state.lastToken === user.token){
        console.log('token has not changed or is new');
    } else {
        console.log('token has changed!');
    }
    this.setState({lastToken: user.token});
  }

  async checkToken() {
    // for some reason if I didn't also await this next call, the caller of checkToken
    // wouldn't wait for it to complete.  I think I've seen some notes about this
    // on stackexchange, but I need to play with this some more to make sure I 
    // fully understand the behavior
    await this.state.keycloak.updateToken(30).then((refreshed) => {
        if (refreshed){
            console.log('refreshed token');
            this.composeUser();
        } else {
            console.log('token was not refreshed but is still valid');
        }
    }).catch(() => {
        console.log('refreshing token failed; trying authorize');
        this.authorizeUser();
    });
  }


  async authorizeUser() {

    var keycloak = new Keycloak({
        url: 'https://auth.simplysolutions.com:8443/auth',
        realm: 'SimplySolutions',
        clientId: 'amex_docs_app',
        enableCors: true,
        });
    this.setState({keycloak: keycloak});
    await keycloak.init({onLoad: 'login-required'}).then((authorized) => {
        console.log(this.state);
        this.setState({keycloak: keycloak});
        this.composeUser();
        console.log(this.state);
    }).catch(function(err) {
        //alert('failed to initialize');
        console.log('failed to initialize');
        console.log(keycloak);
        console.log(err);
    });

  }

  render() {
    // CTB TODO:  Look into app-wide state management with Redux; I don't like passing these around to components
    var props = {
            checkToken: this.checkToken,
            user: this.state.user,
        }
    var is_authorized = false;
    if (this.state.user.roles && this.state.user.roles.includes('americanextractions.documents')){
        is_authorized = true;
    }
    var header = <PublicHeader />
    if (is_authorized){
        header = (
            <div>
                <PrivateHeader user={this.state.user}/>
                <PDFManager {...props}/>
            </div>
        )
    }
    return header
  }
}


export default PrivateLayout;



