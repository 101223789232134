import React from 'react';
import './PrivateHeader.css';
import { Link } from 'react-router-dom';
//import Button from 'react-bootstrap/Button';

function PublicHeader(props) {
  console.log('private header sees user');
  console.log(props.user);
  return (
        <div className="privHead medium-background">
            <div id="logo">
                <a href="https://americanextractions.com/">
                    <img src="https://documents.americanextractions.com/images/logo_horizontal.png" alt="American Extractions" />
                </a>
            </div>
            <div id="menu">
                <ol> {/* CTB TODO:  Make these bootstrap buttons */}
                <li><Link to="/private">Manage Documents</Link></li>
                <li><a href={props.user.preferencesUrl}>Profile</a></li>
                <li><a href={props.user.logoutUrl}>Logout</a></li>
                </ol>
            </div>
        </div>
  );
}

export default PublicHeader;



