import React from 'react';
import './PDFDetail.css';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import PDFDetailHistory from './PDFDetailHistory.js';
import { toast } from 'react-toastify';

class PDFDetail extends React.Component {
    constructor(props){
        super(props);
        function generateFileName(){
            var name = '';
            var availableChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            for (var i = 0; i < 12; i++){
                name += availableChars.charAt(Math.floor(Math.random() * availableChars.length));
            }
            return name;
        }

        var isNew = this.props.new;
        this.state = {
            isNew: isNew,
            doc: this.props.doc || {},
            randomFileName: generateFileName(),
            batchNum: isNew ? '' : this.props.doc.batchNum,
            fileName: isNew ? '' : this.props.doc.fileName,
            originalFileName: isNew ? '' : this.props.doc.originalFileName,
            customer: isNew ? '' : this.props.doc.customer,
            customerWebsite: isNew ? '' : this.props.doc.customerWebsite,
            description: isNew ? '' : this.props.doc.description,
            notes: isNew ? '' : this.props.doc.notes,
            fileForUpload: null,
            buttonEnabled: false,
        }

        this.handleBatchNumChange = this.handleBatchNumChange.bind(this);
        this.handleFileNameChange = this.handleFileNameChange.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handleCustomerWebsiteChange = this.handleCustomerWebsiteChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleNotesChange = this.handleNotesChange.bind(this);
        this.generateBatchNum = this.generateBatchNum.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    generateBatchNum() {
        // the function() ... doFunction() pattern lets us handle recursion depth here
        this.setState({batchNum: this.doGenerateBatchNum(0)});
    }

    doGenerateBatchNum(depth){
        if (depth > 10){
            toast.warn("Unable to generate a unique batch num.  Try again or contact Cameron");
            throw new Error("Recursion too deep");
        }
        var num = '';
        var availableChars = '0123456789';
        for (var i = 0; i < 6; i++){
            num += availableChars.charAt(Math.floor(Math.random() * availableChars.length));
        }
        for (var n = 0; n < this.props.docs.length; n++){
            if (num === parseInt(this.props.docs[n].batchNum)){
                return this.doGenerateBatchNum(++depth);
            }
        }
        return num;
    }

    checkEnablements(){
        if (this.state.batchNum === ""){
            //console.log('button disabled because batch num not provided');
            this.setState({
                buttonEnabled: false,
            });
        } else if (!this.state.isNew &&
                  this.state.doc.customer === this.state.customer &&
                  this.state.doc.customerWebsite === this.state.customerWebsite &&
                  this.state.doc.description === this.state.description &&
                  this.state.doc.notes === this.state.notes &&
                  this.state.doc.fileName === this.state.fileName){
            //console.log('button disabled because this is not a new document and none of the details have been changed');
            this.setState({
                buttonEnabled: false,
            });
        } else {
            this.setState({buttonEnabled: true});
        }
    }

    validateStringEntry(str){
        if (str.includes("<") || str.includes(">")){
            toast.warn("HTML tags are not supported");
            return false;
        } else {
            return true;
        }
    }

    validateInputs(){
        if (!this.state.customerWebsite.startsWith("http://") &&
            !this.state.customerWebsite.startsWith("https://")){
            toast.warn("Website must start with http:// or https://");
            return false;
        }
        return true;
    }

    async handleBatchNumChange(e){
        if (this.state.isNew){
            if (this.validateStringEntry(e.target.value)){
                await this.setState({batchNum: e.target.value});
            }
        } else {
            toast.warn('Batch Number cannot be updated');
        }
        this.checkEnablements();
    }

    async handleFileNameChange(e){
        console.log(e.target.files[0]);
        if(e.target.files[0].type !== 'application/pdf'){
            e.target.value = '';
            toast.warn('file must be a PDF');
        } else {
            const selectedFile = e.target.files[0];
            await this.setState({
                originalFileName: selectedFile.name,
                fileName: this.state.randomFileName,
                fileForUpload: selectedFile
            });
        }
        this.checkEnablements();
    }

    async handleCustomerChange(e){
        if (this.validateStringEntry(e.target.value)){
            await this.setState({customer: e.target.value});
        }
        this.checkEnablements();
    }

    async handleCustomerWebsiteChange(e){
        if (this.validateStringEntry(e.target.value)){
            await this.setState({customerWebsite: e.target.value});
        }
        this.checkEnablements();
    }

    async handleDescriptionChange(e){
        if (this.validateStringEntry(e.target.value)){
            await this.setState({description: e.target.value});
        }
        this.checkEnablements();
    }

    async handleNotesChange(e){
        if (this.validateStringEntry(e.target.value)){
            await this.setState({notes: e.target.value});
        }
        this.checkEnablements();
    }

    async handleSubmit(){
        await this.props.checkToken();
        if (this.validateInputs()){
            toast.info('Saving document');
            var item = {
                            batchNum: this.state.batchNum,
                            fileName: this.state.fileName,
                            originalFileName: this.state.originalFileName,
                            customer: this.state.customer,
                            description: this.state.description,
                            notes: this.state.notes,
                            customerWebsite: this.state.customerWebsite,
                        };
            var response = '';        
            if (this.state.isNew){
                response = await axios.post('https://documents.americanextractions.com/api/coa_meta.php',
                        {
                            token: this.props.user.token,
                            items: [item]
                        });
            } else {
                response = await axios.put('https://documents.americanextractions.com/api/coa_meta.php',
                        {
                            token: this.props.user.token,
                            items: [item]
                        });
            }
            console.log(response);
            // only save the document if the metadata saved appropriately
            if (response.data.status === "ok" && this.state.fileForUpload){
                var formData = new FormData();
                formData.append("token", this.props.user.token);
                formData.append("fileName", this.state.fileName);
                formData.append("fileContents", this.state.fileForUpload);
                console.log(formData.values());
                response = await axios.post('https://documents.americanextractions.com/api/coa_doc.php',
                    formData,
                    {headers: { 'Content-Type': 'multipart/form-data' }}
                );
                console.log(response);
            }
            if (response.data.status !== "ok"){
                toast.error("Unable to save document!", { autoClose: false });
            } else {
                toast.success("document saved");
                this.props.cancel();
            }
        }
    }

    render() {
        return (
            <div>
            <form onSubmit={this.handleSubmit}>
                <div className="formItem center required">
                    <label htmlFor="batchNum" className="formItemSplit left">Batch #</label>
                    <div className="formItemSplit right thin">
                    <input
                        type="text"
                        id="batchNum"
                        name="batchNum"
                        value={this.state.batchNum}
                        onChange={this.handleBatchNumChange}
                        className="formItemSplit right"
                     />
                    <div className={this.state.isNew ? "right" : "hidden"}>
                        <Button variant="inline" className="inline_button" onClick={this.generateBatchNum}>Generate</Button>
                    </div>
                    </div>
                </div>
                <div className="formItem center">
                    <label htmlFor="customer" className="formItemSplit left">Customer</label>
                    <div className="formItemSplit right">
                    <input
                        type="text"
                        id="customer"
                        name="customer"
                        value={this.state.customer}
                        onChange={this.handleCustomerChange}
                        className="formItemSplit right"
                     />
                    </div>
                </div>
                <div className="formItem center">
                    <label htmlFor="customerWebsite" className="formItemSplit left">Customer Web Site</label>
                    <div className="formItemSplit right">
                    <input
                        type="text"
                        id="customerWebsite"
                        name="customerWebsite"
                        value={this.state.customerWebsite}
                        onChange={this.handleCustomerWebsiteChange}
                        className="formItemSplit right"
                     />
                    </div>
                </div>
                <div className="formItem center">
                    <label htmlFor="description" className="formItemSplit left">Description</label>
                    <div className="formItemSplit right">
                    <input
                        type="text"
                        id="description"
                        name="description"
                        value={this.state.description}
                        onChange={this.handleDescriptionChange}
                        className="formItemSplit right"
                     />
                    </div>
                </div>
                <div className="formItem center">
                    <label htmlFor="notes" className="formItemSplit left">Notes</label>
                    <div className="formItemSplit right">
                    <textarea
                        id="notes"
                        name="notes"
                        value={this.state.notes}
                        onChange={this.handleNotesChange}
                        className="formItemSplit right"
                     />
                    </div>
                </div>
                <div className="formItem center">
                    <div className="formItemSplit">
                        <input
                            type="file"
                            id="fileName"
                            name="fileName"
                            //value={this.state.fileName}
                            onChange={this.handleFileNameChange}
                            className={(this.state.isNew || !this.state.fileName) ? "" : "hidden"}
                        />
                        {/*
                        <div>Original filename is {this.state.originalFileName}</div>
                        <div>Uploaded filename is {this.state.fileName}</div>
                        */}
                    </div>
                </div>
                <div>Items in <mark className="required light-background">red</mark> are required</div>
                <div className="formItem center">
                    <div className={this.state.isNew ? "right" : "hidden"}>
                    <Button variant="primary" onClick={this.handleSubmit} disabled={!this.state.buttonEnabled}>Upload</Button>
                    </div>
                    <div className={this.state.isNew ? "hidden" : "right"}>
                    <Button variant="primary" onClick={this.handleSubmit} disabled={!this.state.buttonEnabled}>Update</Button>
                    </div>
                    <div className="right">
                    <Button variant="secondary" onClick={this.props.cancel}>Cancel</Button>
                    </div>
                </div>
            </form>
            <div className={this.state.isNew ? "hidden" : ""}>
                <PDFDetailHistory history={this.state.doc.history}/>
                <div className="highlighted">
                    Current Document:
                    <embed src={"https://documents.americanextractions.com/api/coa_doc.php?fileName=" + this.state.fileName} />
                </div>
            </div>
            </div>
        )
    }

}

export default PDFDetail
