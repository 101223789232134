import React from 'react';
//import ReactDOM from 'react-dom';
//import './PDFList.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';

class PDFList extends React.Component {

    sort_docs( a, b) {
        // reverse sort by created datea
        if (a.history[0].date.slice(0,8) > b.history[0].date.slice(0,8)){
            return -1;
        }
        if (a.history[0].date.slice(0,8) < b.history[0].date.slice(0,8)){
            return 1;
        }
        return 0;
    }

    render() {

        var docRows = this.props.docs.sort(this.sort_docs).map((doc) =>
            <tr key={doc._id.$oid} className="clickable" onClick={() => this.props.getDocumentDetail(false, doc._id.$oid)}>
            <td key="batchNum">{doc.batchNum}</td>
            <td key="fileName">{doc.fileName}</td>
            <td key="originalFileName">{doc.originalFileName}</td>
            <td key="customer">{doc.customer}</td>
            <td key="createdDate">{doc.history[0].date.slice(0,8)}</td>
            <td key="modDate">{doc.history[(doc.history.length - 1)].date.slice(0,8)}</td>
            </tr>
        //).sort(this.sort_docs);
        );

        var content = "";
        if (!this.props.docs || this.props.docs.length === 0){
            content = "No documents found"
        } else {
            content = (
                <table>
                  <thead>
                    <tr>
                      <th>Batch #</th>
                      <th>File Name</th>
                      <th>Uploaded File Name</th>
                      <th>Customer</th>
                      <th>Created Date</th>
                      <th>Modified Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {docRows}
                  </tbody>
                </table>
            )
        }

        return (
            <div>
                <Button className="left" variant="primary" onClick={() => this.props.getDocumentDetail(true, {})}>Upload a new Document</Button>
                <div className="center"><br /></div>
                {content}
            </div>
        )
    }    
}

export default PDFList;

