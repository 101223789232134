import React from 'react';
import './PublicHeader.css';

function PublicHeader() {
  return (
        <div className="pubHead medium-background">
            <a href="https://americanextractions.com/">
                <img src="https://documents.americanextractions.com/images/logo.png" alt="American Extractions" />
            </a>
        </div>
  );
}

export default PublicHeader;



